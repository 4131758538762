import React from "react"

import {
  FaFilePdf as Pdf,
  FaTwitter as Twitter,
  FaLinkedin as Linkedin,
  FaGithub as Github,
  FaGitlab as Gitlab,
  FaInstagram as Insta,
  FaEnvelope as Mail,
} from "react-icons/fa"

export default function info() {
  return (
    <div className="container">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>Hello, I'm</span> <br />
        Şamil Can.
      </div>

      <div className="text-muted my-4 h3 text-justify info">
        I'm a passionate Freelance Software Engineer, mostly working on Web Backend APIs.
        <br/> I like reading, solving math problems, and crafting things.
      </div>

      <div className="h1 mt-5">
      <a className="mr-5 icon" href="https://linkedin.com/in/SamilCan">
          <Linkedin />
        </a>
        <a className="mr-5 icon" href="https://drive.google.com/file/d/1xe8uAUQ4ov9cEFwTLOVv62jOwe_FRiaY/view">
          <Pdf /> Resume
        </a>
        <a className="mr-5 icon" href="https://twitter.com/samcan">
          <Twitter />
        </a>
        <a className="mr-5 icon" href="https://instagram.com/samilcan">
          <Insta />
        </a>
        <a className="mr-5 icon" href="https://github.com/SamilCan">
          <Github />
        </a>
        <a className="mr-5 icon" href="https://gitlab.com/SamilCan">
          <Gitlab />
        </a>
        <a className="mr-5 icon" href="mailto://me@samilcan.com">
          <Mail />
        </a>
      </div>
    </div>
  )
}
